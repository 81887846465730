import { Button, Carousel, Col, Image, Row } from "react-bootstrap";
import Slider1 from "../assets/1.jpg";
import Slider2 from "../assets/bg.png";
import AboutImg from "../assets/abouts.png";
import Vision from "../assets/vision.png";
import Mission from "../assets/mission.png";
import Goals from "../assets/goal.png";
import Values from "../assets/values.png";

function Home() {
  return (
    <>
      <div className="">
        <Carousel>
          <Carousel.Item>
            <Image src={Slider1} className="slider img-slider" />
            <Carousel.Caption className="bg-caption">
              <h4 className="text-left">
                Welcome to Medicoll Nutrasciences Pvt Ltd
              </h4>
              <p>
                Medicoll Nutrasciences Private Limited, always focus on
                improving patients’ lives by identifying, developing and
                producing meaningful products which reflect our motto: “Future
                Health”.
              </p>
              <Button className="btn-medicoll">Order Now</Button>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Image src={Slider2} className="slider" />
            <Carousel.Caption className="bg-caption">
              <h4>
                Medicoll Nutrasciences Pvt Ltd high quality and affordable
                medicines
              </h4>
              <p>
                Medicoll Nutrasciences Private Limited, always focus on
                improving patients’ lives by identifying, developing and
                producing meaningful products which reflect our motto: “Future
                Health”.
              </p>
              <Button className="btn-medicoll">Order Now</Button>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="container">
        <Row className="mx-0 bg-light d-flex align-items-center">
          <Col lg={4}>
            <Image src={AboutImg} className="img-fluid" />
          </Col>
          <Col className="py-3">
            <p className="mb-0 pb-0 text-secondary">
              Welcome to Medicoll Nutrasciences Pvt Ltd.
            </p>
            <h2 className="text-danger heading">About us</h2>
            <p>
              Medicoll Nutrasciences Private Limited, always focus on improving
              patients’ lives by identifying, developing and producing
              meaningful products which reflect our motto: “Future Health”. As a
              part of our commitment to our patients, we continuously expand our
              product portfolio and research & development that can influence
              our identical expertise. We always remain devoted to the highest
              standards of quality, safety and integrity.
            </p>
          </Col>
        </Row>
      </div>
      <div className="bg-mission">
        <div className="container py-3">
          <Row>
            <Col md={2} className="py-3">
              <Image src={Vision} className="img-fluid float-end" />
            </Col>
            <Col className="ps-5 py-3">
              <h5 className="heading">Our Vision</h5>
              <p>
                Reaching people and touching lives as a leading provider of
                Valued Medicines.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="py-3">
              <Image src={Mission} className="img-fluid float-end" />
            </Col>
            <Col className="ps-5 py-3">
              <h5 className="heading">Our Mission</h5>
              <p>
                Our mission is to benefit people and improve their quality of
                life through our quality products. We are committed to achieve
                our goal through skilled, creative and motivated employees of
                the highest professional standard.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="py-3">
              <Image src={Goals} className="img-fluid float-end" />
            </Col>
            <Col className="ps-5 py-3">
              <h5 className="heading">Our Goal</h5>
              <p>
                Our Goal is to meet most healthcare needs of Patients with
                Metabolic Disorders covering maximum spectrum.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="py-3">
              <Image src={Values} className="img-fluid float-end" />
            </Col>
            <Col className="ps-5 py-3">
              <h5 className="heading">Our Values</h5>
              <p>
                We are devoted to ensure quality in all our endeavors and to
                maintain the highest ethical standards in our
                business practices.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Home;
