import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import OurTeam from "../assets/director.jpg";
import Work from "../assets/29.jpg";

function Team() {
  return (
    <>
      <div>
        <Image className="img-fluid opacity-75" src={OurTeam} />
      </div>
      <div className="container">
        <Row>
          <Col>
            <Image src={Work} className="img-fluid" />
          </Col>
          <Col className="pt-5 bg-team my-2 rounded-2">
            <p>
              At Medicoll we recognise that sustainability and success of an
              organisation are dependent on the quality, commitment and
              responsible behaviour of its people.
            </p>
            <p>
              Each of the members in the team are committed to excellence as we
              believe that each one of us has the power to make a positive
              difference at our organisation everyday.
            </p>
            <p>
              We believe that each individual brings unique skills and
              experience that is critical to the success of Medicoll Marking
              Private Limited. These uniqueness makes us stand apart and helps
              us to fulfill our mission of ensuring ideal healthcare for all,
              under the explicit guidance of our efficient board of directors.
            </p>
            <h4 className="heading pb-2">Our Board of Directors</h4>
            <Row>
              <Col>
                <Card className="bg-director border-0 p-3">
                  <h4 className="text-dark text-center fw-bold mb-0">
                    Santhoshi Seeta
                  </h4>
                  <p className="text-dark fs-6 text-center">DIRECTOR</p>
                </Card>
              </Col>
              <Col>
                <Card className="bg-director border-0 p-3">
                  <h4 className="text-dark fw-bold text-center mb-0">
                    Sudheer Seeta
                  </h4>
                  <p className="text-dark fs-6 text-center">DIRECTOR</p>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Team;
