import React, { useState } from "react";
import { Container, Image, Nav, Navbar, Offcanvas } from "react-bootstrap";
import Logo from "../assets/medicolllogo.png";
import { Route, Routes } from "react-router-dom";
import Aboutus from "../pages/Aboutus";
import Home from "../pages/Home";
import { LinkContainer } from "react-router-bootstrap";
import Contact from "../pages/Contact";
import Team from "../pages/Team";
import Products from "../pages/Products";

function Menubar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const menuLinks = {
    name: ["Home", "About Us", "Team", "Products", "Contact Us"],
  };
  return (
    <>
      <Navbar expand="lg" className="bg-bar">
        <Container>
          <Navbar.Brand>
            <Image src={Logo} className="img-fluid" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="main-menu" onClick={handleShow} />
          <Nav className="justify-content-end ms-auto gap-5 m-email">
            <Nav.Link href="#" className="fw-semibold text-white">
              <i className="fas fa-envelope-square"></i>&nbsp;
              coll@medicoll.online
            </Nav.Link>
            <Nav.Link href="#" className="fw-semibold text-white">
              <i className="fas fa-phone-volume"></i>&nbsp; 040 - 2309 2309
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      <div className="d-flex bg-menu">
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Medicoll</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="container flex-column">
              {menuLinks.name.map((link, index) => {
                return (
                  <LinkContainer to={link === "Home" ? "/" : link}>
                    <Nav.Link key={index}>{link}</Nav.Link>
                  </LinkContainer>
                );
              })}
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
        <Nav className="container d-none d-lg-inline-flex">
          {menuLinks.name.map((link, index) => {
            return (
              <LinkContainer to={link === "Home" ? "/" : link}>
                <Nav.Link key={index} className="text-white">
                  {link}
                </Nav.Link>
              </LinkContainer>
            );
          })}
        </Nav>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="About Us" element={<Aboutus />} />
        <Route path="Team" element={<Team />} />
        <Route path="Products" element={<Products />} />
        <Route path="Contact Us" element={<Contact />} />
      </Routes>
    </>
  );
}

export default Menubar;
