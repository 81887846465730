import "./App.css";
import Footer from "./components/Footer";
import Menubar from "./components/Menubar";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Menubar />

      <Footer />
    </BrowserRouter>
  );
}

export default App;
