import React from "react";
import AboutImg from "../assets/aboutus.jpg";
import { Col, Image, Row } from "react-bootstrap";
import AbtImg from "../assets/abt.png";
import Counter from "../components/Counter";

function Aboutus() {
  return (
    <>
      <div className="about">
        <Image src={AboutImg} className="img-fluid opacity-75" />
        <div className="container">
          <h3 className="text-white">About us</h3>
        </div>
      </div>
      <div className="container">
        <Row>
          <Col md={7} className="py-5">
            <h5 className="abt-head">
              Welcome to <b>MEDICOL NUTRASCIENCES PRIVATE LIMITED</b>
            </h5>
            <p>
              Medicoll Nutrasciences Private Limited, always focus on improving
              patients’ lives by identifying, developing and producing
              meaningful products which reflect our motto: “Future Health”. As a
              part of our commitment to our patients, we continuously expand our
              product portfolio and research & development that can influence
              our identical expertise. We always remain devoted to the highest
              standards of quality, safety and integrity.
            </p>
            <Row>
              <Col className="text-center">
                <h2 className="fw-bolder abt-head">
                  <Counter number={10} />
                </h2>
                <p className="fw-bold text-secondary">YEARS OF EXPERIENCED</p>
              </Col>
              <Col className="text-center">
                <h2 className="fw-bold abt-head">
                  <Counter number={2342} />
                </h2>
                <p className="fw-bold text-secondary">HAPPY CUSTOMERS</p>
              </Col>
              <Col className="text-center">
                <h2 className="fw-bold abt-head">
                  <Counter number={11} />
                </h2>
                <p className="fw-bold text-secondary">AWARD WINNING</p>
              </Col>
            </Row>
          </Col>
          <Col>
            <Image src={AbtImg} />
          </Col>
        </Row>
        <Row className="pb-3">
          <Col>
            <h5 className="fw-bolder history">
              Medicoll Nutrasciences Private Limited History
            </h5>
            <p>
              Medicoll Nutrasciences Private Limited, always focus on improving
              patients’ lives by identifying, developing and producing
              meaningful products which reflect our motto: “Future Health”. As a
              part of our commitment to our patients, we continuously expand our
              product portfolio and research & development that can influence
              our identical expertise. We always remain devoted to the highest
              standards of quality, safety and integrity.
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Aboutus;
