import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import ProductImg from "../assets/products.png";
import MultiVit from "../assets/vitamins.png";
import Counter from "../components/Counter";

const Products = () => {
  return (
    <>
      <div className="about">
        <Image src={ProductImg} className="img-fluid" />
        <div className="container">
          <h3 className="text-white">Products</h3>
        </div>
      </div>
      <div className="container">
        <Row>
          <Col className="py-5">
            <h5 className="abt-head text-center">Why Choose Us?</h5>
            <p className="text-center">
              Medicoll Nutrasciences is dedicated to provide comprehensive and
              in-depth solutions that can become the main source for you and
              your family’s overall health. Our distinctive pharmacy platform is
              designed to provide you with more convenient and accessible
              services. Medicoll Nutrasciences fully understands the compliance
              and special medication requirements of our patients and always has
              medications available.
            </p>
            <Row>
              <Col className="vit d-flex justify-content-start align-items-center py-4 ms-2 me-3">
                <div className="icons text-center">
                  <span className="products"></span>
                </div>
                <div className="pt-0 px-4">
                  <h5 className="mb-0 fw-bold">De-Lazy</h5>
                  <p className="mb-0">Composition: multivitamin</p>
                </div>
              </Col>
              <Col className="vit d-flex justify-content-start align-items-center py-4 mx-3">
                <div className="icons text-center">
                  <span className="daelyte"></span>
                </div>
                <div className="pt-0 px-4">
                  <h5 className="mb-0 fw-bold">Dae-lyte</h5>
                  <p className="mb-0">Composition: Vitamin - D</p>
                </div>
              </Col>
              <Col className="vit d-flex justify-content-start align-items-center py-4 ms-3 me-2">
                <div className="icons text-center">
                  <span className="gummy"></span>
                </div>
                <div className="pt-0 px-4">
                  <h5 className="mb-0 fw-bold">Dr. Mummy</h5>
                  <p className="mb-0">Composition: multivitamin</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row className="pb-3">
          <Col>
            <h5 className="fw-bolder history">
              Medicoll Nutrasciences Private Limited History
            </h5>
            <p>
              Medicoll Nutrasciences Private Limited, always focus on improving
              patients’ lives by identifying, developing and producing
              meaningful products which reflect our motto: “Future Health”. As a
              part of our commitment to our patients, we continuously expand our
              product portfolio and research & development that can influence
              our identical expertise. We always remain devoted to the highest
              standards of quality, safety and integrity.
            </p>
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default Products;
