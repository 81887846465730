import React, { useState } from "react";
import { Col, Row, Form, Button, Card, Nav } from "react-bootstrap";
import ContactImg from "../assets/contact.jpg";

function Contact() {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const submitEmailHandler = (e) => {
    // const tocken="6379c45a-20ea-47a8-9a45-a105b7914df0"
    e.preventDefault();
    console.log(userDetails);

    if (window.Email) {
      window.Email.send({
        SecureToken: "4227a4d2-e417-4482-b8b0-aad717b54ad9",
        To: "medicollmarketing@gmail.com",
        From: userDetails.email,
        Subject: userDetails.subject,
        Body: `Name: ${userDetails.name}
        Phone no: ${userDetails.phone}
        Email: ${userDetails.email},
        Subject: ${userDetails.subject}
        Message: ${userDetails.message}`,
      }).then(() => alert("Email sent successfully!!!"));
    }
  };
  return (
    <>
      <section className="login ">
        <Row className="mx-0 stud-test">
          <Col md={1}></Col>
          <Col md={10} className=" my-3">
            <Row className="border border-primary mx-0 px-0 rounded-1">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d237.88193322201!2d78.49853355568631!3d17.454403835467748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b68722f470b%3A0xa824ad27f725d4cf!2sMedicoll%20Nutrasciences%20Private%20Limited!5e0!3m2!1sen!2sin!4v1697622798021!5m2!1sen!2sin"
                height="400"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="px-0"
              ></iframe>
            </Row>
          </Col>
        </Row>
        <Row className="mx-0 stud-test">
          <Col md={1}></Col>
          <Col md={10} className=" my-3">
            <Row className="">
              <Col md={7} className=" shadow-lg bg-white rounded">
                <h3 className="px-3 pt-4 pb-3 text-center">
                  We'll get in touch with you
                </h3>
                <Form className="p-3" onSubmit={submitEmailHandler}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          placeholder="Full name"
                          value={userDetails.name}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              name: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="email"
                          placeholder="Email id"
                          value={userDetails.email}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              email: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="tel"
                          placeholder="Mobile number"
                          value={userDetails.phone}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              phone: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Subject"
                          value={userDetails.subject}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              subject: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Your query here..."
                          value={userDetails.message}
                          onChange={(e) =>
                            setUserDetails({
                              ...userDetails,
                              message: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Button
                      className="w-100 bg-footer"
                      onClick={submitEmailHandler}
                    >
                      Contact Me
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={5} className="px-5 bg-footer shadow-lg pt-5 rounded">
                <h5 className="text-white pb-3">Contact Us</h5>
                <Row className="text-white d-flex justify-content-center">
                  <Col md={1} className="text-end">
                    <i className="fas fa-map-marker-alt text-white"></i>
                  </Col>
                  <Col>
                    <p className="fw-light">
                      <strong>Address:</strong>&nbsp;Plot no 43, 1<sup>st</sup>{" "}
                      Floor Part, W.T.Road, Vikrampuri Colony, Picket,
                      Tirumalagiri, Hyderabad-500009, TS.
                    </p>
                  </Col>
                </Row>
                <Row className="text-white d-flex justify-content-center">
                  <Col md={1} className="text-end">
                    <i className="fas fa-phone text-white"></i>
                  </Col>
                  <Col>
                    <p className="fw-light">
                      <strong>Phone:</strong>&nbsp;040 - 2309 2309
                    </p>
                  </Col>
                </Row>
                <Row className="text-white d-flex justify-content-center">
                  <Col md={1} className="text-end">
                    <i className="fas fa-envelope text-white"></i>
                  </Col>
                  <Col>
                    <p className="fw-light">
                      <strong>Email:</strong>&nbsp;coll@medicoll.online
                    </p>
                  </Col>
                </Row>
                <Row className="text-white d-flex justify-content-center">
                  <Col md={1} className="text-end">
                    <i className="fas fa-globe-asia text-white"></i>
                  </Col>
                  <Col>
                    <p className="fw-light">
                      <strong>Website:</strong>
                      &nbsp;www.medicoll.online
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default Contact;
