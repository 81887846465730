import React from "react";
import CountUp from "react-countup";

function Counter({ number }) {
  return (
    <>
      <CountUp duration={10} className="counter" end={number} />
    </>
  );
}

export default Counter;
